@import url('//fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

.nunito {
  font-family: 'Nunito Sans', sans-serif;
}

.content {
  max-width: 1120px;
  justify-self: center;
  margin: 0 auto;
  padding: 0 5%;
}

.title {
  line-height: 90px;
  margin-bottom: 1rem;
  font-weight: 800;
}

.lead {
  font-family: 'Poppins', sans-serif;
  color: rgba(40, 40, 40, 0.6);
  font-size: 22px;
  margin-bottom: 0.5rem;
}

.btn-link {
  @apply bg-ivao-blue-main text-white p-4 inline-block  rounded-md font-semibold
}

.btn-link {
  transition: all 0.2s ease-in-out;
}

.btn-link:hover { 
  filter: brightness(0.8);
}

.card-lead {
  color: rgba(40, 40, 40, 0.6);
}

.footer-link {
  text-decoration: underline;
}

.menu a:hover {
  @apply border-b-ivao-blue-main border-b-2 text-ivao-blue-main;
}

.menu a {
  margin-bottom: -1px;
  line-height: 75px;
}

.active {
  @apply font-bold border-b-ivao-blue-main border-b-2
}

xmp {
  @apply text-center bg-gray-200 p-2 border-gray-300 border rounded;
}

.docs-title {
  @apply nunito text-ivao-blue-main font-extrabold text-3xl my-4
}