@import "//fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.static {
  position: static;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-7 {
  gap: 1.75rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-b-ivao-blue-main {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(13 44 153 / var(--tw-border-opacity));
}

.bg-ivao-success {
  --tw-bg-opacity: 1;
  background-color: rgb(46 198 98 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.p-2 {
  padding: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-semibold {
  font-weight: 600;
}

.text-ivao-blue-main {
  --tw-text-opacity: 1;
  color: rgb(13 44 153 / var(--tw-text-opacity));
}

.text-ivao-success {
  --tw-text-opacity: 1;
  color: rgb(46 198 98 / var(--tw-text-opacity));
}

.outline-2 {
  outline-width: 2px;
}

body {
  font-family: Poppins, sans-serif;
}

.nunito {
  font-family: Nunito Sans, sans-serif;
}

.content {
  justify-self: center;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 5%;
}

.title {
  margin-bottom: 1rem;
  font-weight: 800;
  line-height: 90px;
}

.lead {
  color: #28282899;
  margin-bottom: .5rem;
  font-family: Poppins, sans-serif;
  font-size: 22px;
}

.btn-link {
  --tw-bg-opacity: 1;
  background-color: rgb(13 44 153 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .375rem;
  padding: 1rem;
  font-weight: 600;
  transition: all .2s ease-in-out;
  display: inline-block;
}

.btn-link:hover {
  filter: brightness(.8);
}

.card-lead {
  color: #28282899;
}

.footer-link {
  text-decoration: underline;
}

.menu a:hover {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(13 44 153 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(13 44 153 / var(--tw-text-opacity));
}

.menu a {
  margin-bottom: -1px;
  line-height: 75px;
}

.active {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-bottom-color: rgb(13 44 153 / var(--tw-border-opacity));
  font-weight: 700;
}

xmp {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  text-align: center;
  border-radius: .25rem;
  padding: .5rem;
}

.docs-title {
  --tw-text-opacity: 1;
  color: rgb(13 44 153 / var(--tw-text-opacity));
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Nunito Sans, sans-serif;
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 2.25rem;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (width >= 768px) {
  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }
}

/*# sourceMappingURL=index.37b30a25.css.map */
